<template>
  <section>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <h3 class="text-white mb-4">Import Data Member</h3>
        <b-overlay v-bind:show="isLoading"
                   rounded
                   opacity="0.6"
                   spinner-small
                   spinner-variant="primary"
        >
          <div class="row">
            <div class="col">
              <input ref="register-member" type="file"
                     class="form-control-file text-uppercase font-weight-normal-bold text-light outline-light"
                     @change="register"/>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-10">
        <h3 class="text-white mb-4">Please select Event</h3>
        <router-link :key="event.id" v-for="event in events" :to="{name: 'management/event', params: {id: event.id}}"
                     class="btn btn-light btn-block outline-light text-uppercase btn-lg text-primary mb-5">
          {{ event.name }}
        </router-link>
        <h3 class="text-white mb-4">Feature</h3>
        <router-link :to="{name: 'management/blast'}" class="btn btn-primary outline-light btn-block mb-5">
          Blast Mail <font-awesome-icon icon="paper-plane"/>
        </router-link>
        <router-link :to="{name: 'management/certificate'}" class="btn btn-primary outline-light btn-block mb-5">
          Blast Certificate <font-awesome-icon icon="paper-plane"/>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get, set, update} from "firebase/database";

export default {
  name: 'Index',
  methods: {
    register() {
      let file = this.$refs["register-member"];
      if (file && file.files[0]) {
        this.isLoading = true;
        this.read(file.files[0]).then(
            function (data) {
              if (this.uploadWithIndex) {
                this.update(data)
              } else {
                let reference = ref(database, 'guest');
                set(reference, data).then(() => alert('Data uploaded'));
              }
              this.isLoading = false;
            }.bind(this),
            (error) => {
              alert(error.message)
              this.isLoading = false
            }
        );

        file.value = null
      }
    },
    read(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function () {
          let content = reader.result.replace(/[^\w\s\-\+\.@,;]/g, '');
          let rows = content.split("\r\n");
          if (rows.length > 2) {
            let data = [];
            let headers = ['id', 'name', 'number'].concat(this.additionalHeaders);
            for (let line = 1; line < rows.length; line++) {
              if (rows[line]) {
                let columns = rows[line].split(/[;]/);
                let member = {};
                headers.forEach(function (header, index) {
                  if (columns[index]) {
                    member[header] = columns[index]
                  }
                });
                data.push(member);
              }
            }
            return resolve(data);
          } else {
            return reject({message: "Error. No data imported"});
          }
        }.bind(this);
        reader.readAsBinaryString(file);
      });
    },
    update(data) {
      data.forEach((guest) => {
        guest['email'] = guest['email'].toLowerCase();

        let reference = ref(database, 'guest/' + guest['id']);
        update(reference, guest);
      })
      alert('Data uploaded');
    },
  },
  data() {
    return {
      events: [],
      members: [],
      isLoading: false,
      uploadWithIndex: process.env.VUE_APP_UPLOAD_WITH_INDEX || false,
      additionalHeaders: process.env.VUE_APP_ADDITIONAL_HEADERS ? process.env.VUE_APP_ADDITIONAL_HEADERS.split(",") : []
    };
  },
  mounted() {
    let reference = ref(database, 'event');
    get(reference).then((snapshot) => {
      if (snapshot.exists()) {
        this.events = snapshot.val().filter(e => e)
      }
    });

    document.title = "Home Management";
  }
}
</script>