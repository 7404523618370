import Vue from 'vue'
import Vuex from 'vuex'
import {collection, addDoc} from "firebase/firestore";
import {firestore} from "@/service/firebase";
import Email from '../components/Mail'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isAuthenticated: false,
    },
    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
    },
    mutations: {
        authenticate(state, payload) {
            state.isAuthenticated = payload.isAuthenticated;
        },
    },
    actions: {
        login(context, payload) {
            let status = window.btoa(payload) === process.env.VUE_APP_PASSCODE;
            if (status) {
                context.commit('authenticate', {isAuthenticated: true});
            }
            return {status: status}
        },
        sendEmail(context, payload) {
            const app = new Vue({
                render: (h) => h(Email, {
                    props: {
                        name: payload.name
                    }
                }),
            }).$mount()

            return addDoc(collection(firestore, process.env.VUE_APP_EMAIL_DIRECTORY), {
                to: `${payload.name} <${payload.email}>`,
                message: {
                    subject: "Thank You for Joining Mandiri Sustainability Forum 2022!",
                    text: `Dear ${payload.name}, you receive this email because you have register for Mandiri Sustainability Forum 2022. If you not do this, someone may have registered this event for you. Please ignore this email if you did not register for this event.\n
                     Please click link below to join our event.\n
                     Zoom Invitation https://bit.ly/msf2022com\n
                     Meeting ID: 96092791074.\n
                     Passcode: msf2022\n\n
                     You can also watch online from link below.\n
                     Bank Mandiri Youtube https://bmri.id/msf22live\n
                     For further information please contact info@msf.co.id\n
                      This is an automation E-mail, please do not reply.\n
                       © 2022 All Rights Reserved | Mandiri Sustainability Forum https://msf.co.id`,
                    html: app.$el.outerHTML,
                },
            });
        },
        blastEmail(context, payload) {
            let member = payload.member;
            let message = payload.message;
            let attachments = payload.attachments !== undefined ? payload.attachments : [];
            return addDoc(collection(firestore, process.env.VUE_APP_EMAIL_DIRECTORY), {
                to: `${member.name} <${member.email}>`,
                message: {
                    subject: message.subject,
                    text: message.content.replace(/(<([^>]+)>)/gi, ""),
                    html: message.content,
                    attachments: attachments,
                },
            });
        }
    },
    modules: {}
})
