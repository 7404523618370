<template>
  <section>
    <div class="row justify-md-content-between justify-content-center">
      <div class="col-12">
        <h1 class="text-light text-center">Event Genius</h1>
      </div>
    </div>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get} from "firebase/database";

export default {
  name: 'Index',
  data() {
    return {
      links: [],
    };
  },
  mounted() {
    document.title = "Home";
  }
}
</script>