<template>
  <section class="mt-5">
    <router-view></router-view>
  </section>
</template>

<script>

export default {
  name: "management-layout",
};
</script>