<template>
  <div>
    <div style="margin-bottom: 20px;">
      <p>Dear <strong>{{ name }}</strong>, you receive this email because you have register for Mandiri Sustainability
        Forum 2022. If you not do this, someone may have registered this event for you. Please ignore this email if you
        did not register for this event.</p>
    </div>
    <div style="text-align: center; margin-bottom: 20px;">
      <a :href="zoomUrl">
        <img :src="imageUrl" alt="MSF 2022 Invitation" width="100%"/>
      </a>
    </div>
    <div style="text-align: center">
      <p style="text-align: center; margin-bottom: 10px;">In case link not working, please click link below to join our
        event</p>
      <a :href="zoomUrl" style="
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            margin-bottom: 1rem;
            line-height: 1.5;
            border-radius: 50rem;
            color: #41b5a1; background-color: #e6e6e6">
        Zoom Invitation
      </a>
      <p style="text-align: center; margin-bottom: 30px;">or copy this link {{ zoomUrl }} to your browser.</p>

      <p>
        Meeting ID:<br/>
        <strong>96092791074</strong>
      </p>
      <p>
        Passcode:<br/>
        <strong>msf2022</strong>
      </p>

      <p style="text-align: center; margin-bottom: 10px; margin-top: 30px;">You can also watch online from link
        below.</p>

      <a :href="youtubeUrl" style="
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            margin-bottom: 1rem;
            line-height: 1.5;
            border-radius: 50rem;
            color: #ffffff; background-color: #ff0000">
        Bank Mandiri Youtube
      </a>
      <p style="text-align: center; margin-bottom: 30px;">or copy this link {{ youtubeUrl }} to your browser.</p>

      <p>For further information please contact <a href="mailto:info@msf.co.id" style="text-decoration: none">info@msf.co.id</a>
      </p>

      <small>This is an automation E-mail, please do not reply.</small>

      <hr/>
      <p>© 2022 All Rights Reserved | <a :href="baseUrl" style=" color: #41b5a1;">Mandiri Sustainability Forum</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Mail',
  props: {
    name: String,
  },
  data() {
    return {
      imageUrl: location.origin + "/event/email-banner.jpg",
      baseUrl: location.origin,
      zoomUrl: "https://bit.ly/msf2022com",
      youtubeUrl: "https://bmri.id/msf22live",
    };
  }
};
</script>