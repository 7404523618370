import {initializeApp} from "firebase/app";
import {getDatabase} from "firebase/database";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: "api-project-550813444903.firebaseapp.com",
    databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
    projectId: "api-project-550813444903",
    storageBucket: "api-project-550813444903.appspot.com",
    messagingSenderId: "550813444903",
    appId: "1:550813444903:web:425b5ee31880aa5ce05702",
    measurementId: "G-L6R0XD1TCQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebase = app;

export const database = getDatabase(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);