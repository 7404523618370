<template>
  <section>
    <div class="row justify-content-center align-items-center mb-5">
      <div class="col-auto">
        <router-link :to="{name: 'management/index'}">
          <img src="@/assets/images/icon-home.png" alt="Home" style="width: 60px"/>
        </router-link>
      </div>
      <div class="col-auto">
        <button class="btn btn-link text-light" type="button" @click="download">
          <font-awesome-icon icon="file-download" size="3x" class="ml-2"/>
        </button>
      </div>
      <div class="col-auto">
        <button class="btn btn-link text-light" type="button"
                v-b-popover.focus.top="'Regular: ' + totalRegular + ' | MDR: ' + totalMDR"
                title="Jumlah kehadiran">
          <font-awesome-icon icon="user-check" size="lg" class="ml-2"/>
          <div>{{ totalAll }}</div>
        </button>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <input v-model="form.keyword" class="form-control outline-light text-primary"
               placeholder="Silakan ketik nama peserta..."/>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <template v-if="filterMembers.length">
        <div class="col-10">
          <table class="table text-white">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Attend</th>
              <th>Action</th>
            </tr>
            <tr :key="member.id" v-for="member in filterMembers">
              <td>{{ member.id }}</td>
              <td>{{ member.name }}</td>
              <td>{{ attend(member) }}</td>
              <td class="text-center align-middle">
                <button v-if="hasAttend(member)" @click="remove(member)" class="btn btn-sm btn-link p-0 text-white"
                        type="button"
                        title="Delete Kehadiran">
                  <font-awesome-icon icon="times"/>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </template>
      <div v-else class="col-8">
        <p class="text-center font-weight-bold text-white mb-5">Data tidak ditemukan</p>
      </div>
    </div>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get, remove} from "firebase/database";
import {saveAs} from 'file-saver';

export default {
  name: 'ManagementIndex',
  props: {
    id: {
      required: true
    }
  },
  watch: {
    event(event) {
      let guestRef = ref(database, 'guest');
      get(guestRef).then((snapshot) => {
        if (snapshot.exists()) {
          this.members = Object.values(snapshot.val()).filter(e => e)
        }
      });

      if (event) {
        let attendRef = ref(database, 'event-guest/' + event.id);
        get(attendRef).then((snapshot) => {
          if (snapshot.exists()) {
            this.attendances = snapshot.val()
          }
        })
      }
    },
    attendances() {
      this.memberAttends = this.members.filter(this.hasAttend);
    }
  },
  computed: {
    filterMembers() {
      if (this.members.length) {
        return this.members.filter(member => member.name.toLowerCase().includes(this.form.keyword.toLowerCase()))
      } else {
        return [];
      }
    },
    totalAll() {
      return this.memberAttends.length;
    },
    totalMDR() {
      return this.memberAttends.filter(member => member.name.endsWith('MDR')).length
    },
    totalRegular() {
      return this.totalAll - this.totalMDR;
    },
  },
  methods: {
    remove(member) {
      if (confirm('Delete ' + member.name + ' attend?')) {
        let eventGuestRef = ref(database, 'event-guest/' + this.event.id + '/' + member.id);
        remove(eventGuestRef).then(() => this.$delete(this.attendances, member.id));
      }
    },
    attend(member) {
      return this.hasAttend(member) ? this.$moment(this.attendances[member.id].datetime, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY - HH.mm') : null;
    },
    hasAttend(member) {
      return this.attendances[member.id] !== undefined;
    },
    download() {
      let headers = ['id', 'name', 'number'].concat(this.additionalHeaders);
      headers.push('attend');

      let lines = [];
      lines.push(headers.join(';'))

      this.filterMembers.forEach(member => {
        let data = [];
        headers.forEach(header => {
          if (member[header]) {
            data.push(member[header])
          }
        });
        if (this.hasAttend(member)) {
          data.push(this.attendances[member.id].datetime)
        }

        lines.push(data.join(';'))
      })

      let blob = new Blob([lines.join("\r\n")], {type: "text/csv;charset=utf-8"});
      saveAs(blob, this.event.name + ".csv");
    }
  },
  data() {
    return {
      form: {
        keyword: '',
      },
      members: [],
      memberAttends: [],
      attendances: [],
      event: null,
      additionalHeaders: process.env.VUE_APP_ADDITIONAL_HEADERS ? process.env.VUE_APP_ADDITIONAL_HEADERS.split(",") : []
    };
  },
  mounted() {
    let eventRef = ref(database, 'event/' + this.id);
    get(eventRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.event = snapshot.val()

        document.title = this.event.name;
      } else {
        this.$router.replace({
          name: "error", params: {code: 404, title: "Event not found"}
        })
      }
    });
  }
}
</script>
