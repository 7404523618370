<template>
  <section>
    <div class="row justify-content-center">
      <div class="col-10">
        <form v-on:submit.prevent="login">
          <label for="passcode" class="font-weight-bold text-white">Passcode</label>
          <div class="row">
            <div class="col">
              <input id="passcode" type="password" v-model="form.passcode" class="form-control"
                     placeholder="Please input passcode"/>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ManagementLogin',
  data() {
    return {
      form: {
        passcode: ''
      },
    };
  },
  methods: {
    login() {
      this.$store.dispatch('login', this.form.passcode).then(response => {
        if (response.status) {
          this.$router.replace({name: 'management/index'});
        } else {
          alert('Invalid passcode');
        }
      });
    }
  },
  mounted() {
    document.title = "Login Management";
  }
}
</script>