<template>
  <section>
    <div class="row justify-content-center align-items-center mb-5">
      <div class="col-10">
        <form @submit.prevent="send">
          <div class="form-group">
            <label class="text-light">Subject</label>
            <input v-model="form.message.subject" class="form-control" required/>
          </div>
          <div class="form-group">
            <label class="text-light">HTML Content</label>
            <textarea v-model="form.message.content" class="form-control rounded"
                      style="min-height: 10em; font-family: monospace" required/>
          </div>

          <div class="row justify-content-around">
            <div class="col-auto">
              <router-link :to="{name: 'management/index'}">
                <img src="@/assets/images/icon-home.png" alt="Home" style="width: 60px"/>
              </router-link>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-light text-primary mt-3">
                Send Blast Email
                <font-awesome-icon icon="paper-plane"/>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-10">
        <input v-model="form.keyword" class="form-control outline-light text-primary"
               placeholder="Silakan filter nama peserta..."/>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <template v-if="filterMembers.length">
        <div class="col-10">
          <table class="table text-white">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
            </tr>
            <tr :key="member.id" v-for="member in filterMembers">
              <td>{{ member.id }}</td>
              <td>{{ member.name }}</td>
              <td>{{ member.email }}</td>
            </tr>
          </table>
        </div>
      </template>
      <div v-else class="col-8">
        <p class="text-center font-weight-bold text-white mb-5">Data tidak ditemukan</p>
      </div>
    </div>
  </section>
</template>

<script>
import {database} from "@/service/firebase";
import {ref, get, remove} from "firebase/database";

export default {
  name: 'ManagementBlast',
  computed: {
    filterMembers() {
      if (this.members.length) {
        return this.members.filter(member => {
          let keyword = this.form.keyword.toLowerCase();
          return member.name.toLowerCase().includes(keyword) || member.email.toLowerCase().includes(keyword);
        })
      } else {
        return [];
      }
    },
  },
  methods: {
    send() {
      if (confirm('This will send email to filtered member show below. Proceed?')) {
        this.filterMembers.forEach(member => {
          this.$store.dispatch('blastEmail', {
            member: member,
            message: this.form.message
          }).then(document => console.log(document.id));
        })

        this.$root.$bvToast.toast("Please sit down and relax", {
          title: 'Your Email Blast in Queue',
          autoHideDelay: 5000,
          variant: "primary",
          solid: true
        })

        this.form.keyword = '';
      }
    }
  },
  data() {
    return {
      form: {
        keyword: '',
        message: {
          subject: '',
          content: '',
        }
      },
      members: [],
    };
  },
  mounted() {
    let guestRef = ref(database, 'guest');
    get(guestRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.members = Object.values(snapshot.val()).filter(e => e)
      }
    });
  }
}
</script>
