<template>
  <section class="main-layout">
    <div class="container-fluid">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>

export default {
  name: "base-layout",
};
</script>